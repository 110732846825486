export default {
	data: {
		selectedImageIndex: undefined,
		openstreetmap: false
	},
	beforeMount: function () {

		const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
		var cookieName = 'allowOpenstreetmap_' + suffix;
		var allowOpenstreet = localStorage.getItem(cookieName);
		if (allowOpenstreet) {
			this.openstreetmap = true;
		}

		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['website', 'v-office.com']).join('@');
		},
		phoneLink: function () {
			if (VOFFICE.phoneLink) {
				var el = document.getElementById('preloader');

				window.location.href = VOFFICE.phoneLink;
				if (el) {
					el.style.display = "none";
				}
			}
		},
		phoneView: function () {
			if (VOFFICE.phoneView) {
				return VOFFICE.phoneView;
			}
		}
	},
	methods: {
		showCustomModal: function (id) {
			let wh = window.innerHeight;

			console.log('showCustomModal');

			// Get the modal
			let modal;

			modal = document.getElementById('unit-desktop-video');
			console.log("modal is ==>" + modal);
			if (!modal) {
				modal = document.getElementById(id);
			}

			if (modal) {
				console.log("inside if modal");
				var dialog = modal.getElementsByClassName("modal-custom-dialog")[0];
				var content = modal.getElementsByClassName("modal-custom-content")[0];
				var body = document.body;

				if (dialog && content) {
					console.log("inside dialog and content condition==>" + dialog);
					dialog.style.height = wh - 40 + "px";
					content.style.height = wh - 120 + "px";
					console.log("inside dialog and content condition--1");
					modal.style.display = "block";
					console.log("inside dialog and content condition--2");
					body.classList.add('modal-open');
					console.log("inside dialog and content condition--3");

					// When the user clicks anywhere outside of the modal, close it
					window.onclick = function (event) {
						if (event.target == modal) {
							modal.style.display = "none";
							body.classList.remove('modal-open');
						}
					};
				}
			}
		},

		closeCustomModal: function (id) {
			const modal = document.getElementById(id);
			var body = document.body;
			modal.style.display = "none";
			body.classList.remove('modal-open');


			//Stop youtube video



			var iFrame = modal.getElementsByTagName("iframe")[0];
			if (iFrame) {
				var video = iFrame.getAttribute("src");
				iFrame.setAttribute("src", "");
				iFrame.setAttribute("src", video);
			}

		},

		setCookie: function (name) {
			localStorage.setItem(name, true);
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		unitImages: function (string) {
			//console.log(string);
		}
	},

};